import {createSelector} from 'reselect';

const entityKey = 'app.entity';
const entityEventsKey = `${entityKey}.events`;

export const entitySelector = createSelector(
  (state) => state?.entity || state?.[entityKey]?.entity,
  (entity) => entity
);
export const dynamicAttributesSelector = createSelector(entitySelector, (entity) => entity?.dynamicAttributes || []);
export const spotifyArtistIdSelector = createSelector(
  dynamicAttributesSelector,
  (dynamicAttributes) => dynamicAttributes.find((attr) => attr.name === 'spotifyArtistId')?.value
);
export const geoIncludeRadiusEventsSelector = createSelector(
  (state) => state[`${entityEventsKey}.geoIncludeRadius`],
  (events = {}) => events
);
export const geoExcludeRadiusSelector = createSelector(
  (state) => state[`${entityEventsKey}.geoExcludeRadius`],
  (events = {}) => events
);
export const allEventsSelector = createSelector(
  (state) => state[`${entityEventsKey}.all`],
  (events = {}) => events
);
export const eventsSelector = createSelector(
  (state) => state[`${entityKey}-eventPanel-EVENT_TICKETS`],
  (events = {}) => events
);
export const mainEventsSelector = createSelector(
  (state) => state[`${entityKey}-eventPanel-EVENT_TICKETS-mainEvents`],
  (events = {}) => events
);
export const nearbyEventsSelector = createSelector(
  (state) => state[`${entityEventsKey}.nearby`],
  (events = {}) => events
);
export const distantEventsSelector = createSelector(
  (state) => state[`${entityEventsKey}.distant`],
  (events = {}) => events
);

export const entityLoadedSelector = createSelector(
  geoIncludeRadiusEventsSelector,
  geoExcludeRadiusSelector,
  allEventsSelector,
  eventsSelector,
  mainEventsSelector,
  (localEvents, remoteEvents, allEvents, events, mainEvents) => {
    return (
      (localEvents.loading === false && remoteEvents.loading === false) ||
      events.loading === false ||
      mainEvents.loading === false ||
      allEvents.loading === false
    );
  }
);

export const eventsLoadingSelector = createSelector(
  nearbyEventsSelector,
  distantEventsSelector,
  allEventsSelector,
  (nearbyEvents, distantEvents, allEvents) => {
    return nearbyEvents.loading || distantEvents.loading || allEvents.loading;
  }
);

export const noEventsSelector = createSelector(
  entityLoadedSelector,
  geoIncludeRadiusEventsSelector,
  geoExcludeRadiusSelector,
  allEventsSelector,
  eventsSelector,
  mainEventsSelector,
  (loaded, localEvents, remoteEvents, allEvents, events, mainEvents) => {
    return (
      loaded &&
      ((localEvents.numFound === 0 && remoteEvents.numFound === 0) ||
        events.numFound === 0 ||
        mainEvents.numFound === 0 ||
        allEvents.numFound === 0)
    );
  }
);

export const groupedEventsSelector = createSelector(
  geoIncludeRadiusEventsSelector,
  geoExcludeRadiusSelector,
  eventsSelector,
  mainEventsSelector,
  nearbyEventsSelector,
  distantEventsSelector,
  allEventsSelector,
  (local, remote, events, main, nearby, distant, all) => ({
    local: local.events || [],
    remote: remote.events || [],
    events: events.events || [],
    main: main.events || [],
    nearby: nearby.events || [],
    distant: distant.events || [],
    all: all.events || [],
  })
);

export const groupedEventsTotalSelector = createSelector(
  nearbyEventsSelector,
  distantEventsSelector,
  allEventsSelector,
  (nearbyEvents, distantEvents, allEvents) => {
    return {
      nearby: nearbyEvents.numFound || 0,
      distant: distantEvents.numFound || 0,
      all: allEvents.numFound || 0,
    };
  }
);
