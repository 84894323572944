import {DELIVERY_TYPE_IDS} from '@stubhub/delivery-utils';

import {SECONDS_OF_MIN} from './timeConsts';

const MINS_15 = 15;
const DEFAULT_CACHE_MAX_AGE = 300;

export const SEARCH_CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_SEARCH, 10) || 5 * SECONDS_OF_MIN; // 5mins
export const CATALOG_CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_CATALOG, 10) || MINS_15 * SECONDS_OF_MIN; // 15mins
export const CMS_CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_CATALOG, 10) || MINS_15 * SECONDS_OF_MIN; // 15mins
export const BILLBOARD_CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_BILLBOARD, 10) || SECONDS_OF_MIN; // 1min
export const CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_SEARCH, 10) || DEFAULT_CACHE_MAX_AGE;

export const CATEGORY_IDS = {
  CONCERTS: 1,
  SPORTS: 28,
  MOTOR: 110,
  GOLF: 111,
  WRESTLING: 113,
  FOOTBALL: 114,
  THEATER: 174,
  BALLET_AND_DANCE: 176,
  OPERA_CLASSICAL_MUSIC: 178,
  SPEAKING_TOUR_CONVENTIONS: 194,
  COMEDY: 209,
  FAMILY: 5242,
  RODEO: 6979,
  FIGHT: 7368,
  TENNIS: 7667,
  HORSE_RACING: 7851,
  ALTERNATIVE: 178737,
  COUNTRY_FOLK: 195487,
  DANCE_ELECTRONIC: 195489,
  HARD_ROCK_METAL: 195736,
  RAP_HIP_HOP: 195739,
  RB_SOUL: 195742,
  POP: 195743,
  ROCK: 209736,
  FESTIVALS: 490277,
  MUSICAL: 700188,
  STAGE_SHOWS_PLAYS: 700189,
  HARDCORE: 739171,
  INDIE: 739173,
  PUNK: 739181,
};

export const PERFORMER_IDS = {
  COMMUNITY_SHIELD: 735422,
  EURO_2020: 1520757,
  EVERTON: 165237,
  GIFT_CARD: 7033,
  MANCHESTER_CITY: 165242,
  MANCHESTER_UNITED: 7367,
  METALLICA: 8147,
};

export const GROUPING_IDS = {
  CHAMPIONS_LEAGUE: 727754,
  COLLEGUE_BASKETBALL: 333,
  COLLEGUE_FOOTBALL: 122,
  SUPERBOWL: 542,
  EURO_2024: 1507012,
  EUROPA_LEAGUE: 727752,
  EUROPA_LEAGUE_FINAL: 50031079,
  FA_CUP: 720071,
  MLB: 81,
  NBA: 115,
  NFL: 121,
  NHL: 144,
  PREMIER_LEAGUE: 154987,
  SOCCER_TOURNAMENT: 165489,
  WORLD_SERIES: 121418,
  ENGLAND_NATIONAL_FOOTBALL_LEAGUE: 711304,
};

export const paperTicketMatchIds = [
  DELIVERY_TYPE_IDS.FEDEX,
  DELIVERY_TYPE_IDS.PICKUP,
  DELIVERY_TYPE_IDS.UPS,
  DELIVERY_TYPE_IDS.ROYAL_MAIL,
  DELIVERY_TYPE_IDS.DEUTSCHE_POST,
  DELIVERY_TYPE_IDS.COURIER,
  DELIVERY_TYPE_IDS.LOCAL_DELIVERY,
];
export const electronicTicketMatchIds = [DELIVERY_TYPE_IDS.ELECTRONIC, DELIVERY_TYPE_IDS.ELECTRONIC_INSTANT_DOWNLOAD];
export const mobileTicketMatchIds = [
  DELIVERY_TYPE_IDS.MOBILEID,
  DELIVERY_TYPE_IDS.MOBILE_TICKET,
  DELIVERY_TYPE_IDS.MOBILE_TICKET_INSTANT,
  DELIVERY_TYPE_IDS.ELECTRONIC_AND_MOBILE_TICKET,
  DELIVERY_TYPE_IDS.ELECTRONIC_AND_MOBILE_TICKET_INSTANT,
];
export const mobileTransferMatchIds = [DELIVERY_TYPE_IDS.MOBILE_TRANSFER];

// https://wiki.stubcorp.cloud/pages/viewpage.action?pageId=45336440
export const EXTERNAL_SOURCE_IDS = {
  STUBHUB_SEO: 30001,
};

export const CMA_DEFAULTS = {
  GEO_ID: 6628,
};

// https://stubhubint.atlassian.net/browse/BUY-3677
export const DEFAULT_GEOID_BY_STORE = {
  2: 6628,
  3: 9923,
  4: 6628,
  5: 48682,
  6: 213501,
  7: 12984,
  9: 447726,
  10: 58482,
  11: 213500,
  12: 445109,
  13: 213499,
  14: 6628,
  15: 447585,
  16: 443536,
  17: 447586,
  18: 438074,
  19: 213502,
  20: 438073,
};

export const TICKET_MEDIUMS = Object.freeze({
  PAPER: 1,
  PDF: 2,
  BARCODE: 3,
  FLASH_SEAT: 4,
  SEASON_CARD: 5,
  EVENT_CARD: 6,
  EXT_MOBILE: 7,
  EXT_FLASH: 8,
  MOBILE: 9,
  WRISTBAND: 10,
  RFID: 11,
  GUEST_LIST: 12,
});

export const DEVICE_TYPE = {
  PHONE: 'phone',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

export const SCREEN_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const DISPLAY_TYPE_EVENT_LIST = 'List';
export const DISPLAY_TYPE_DATE_CARDS = 'DateCards';
export const DISPLAY_TYPE_CALENDAR = 'Calendar';
export const DISPLAY_TYPE_EVENTS_ONLY = 'EVENT_TICKETS';
export const DISPLAY_TYPE_VIP = 'VIP_TICKETS';
export const DISPLAY_TYPE_OTHER = 'OTHER_TICKETS';
